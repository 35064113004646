import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-menu-item", {
    attrs: {
      index: _vm.routerInfo.name,
      route: {
        parameters: _vm.routerInfo.parameters
      }
    }
  }, [_c("i", {
    class: "el-icon-" + _vm.routerInfo.meta.icon
  }), _c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v(_vm._s(_vm.menusTitle(_vm.routerInfo.meta.title)))])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };