export default {
  name: 'MenuItem',
  props: {
    routerInfo: {
      default: function _default() {
        return null;
      },
      type: Object
    }
  },
  methods: {
    menusTitle: function menusTitle(item) {
      if (this.$te('menus.' + item)) {
        return this.$t('menus.' + item);
      }
      return item;
    }
  }
};